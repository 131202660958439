<template>
  <div id="register">
    <AppContainer :use-background="true">
      <ButtonBack class="btn-back position-absolute" />
      <div class="register">
        <img
          class="logo"
          alt="logo-prego"
          src="@/assets/image/home_top/prego.svg"
        />
        <div
          class="register-options"
          v-if="
            this.urlRedirectFacebook !== null && this.urlRedirectLine !== null
          "
        >
          <button
            @click.prevent="redirectUrlLine"
            class="register-item register-item__line"
          >
            <img alt="icon-line" src="../assets/image/line.svg" />
            <div class="register-item-text f-w3">LINEで登録</div>
          </button>
          <button
            @click.prevent="redirectUrlFacebook"
            class="register-item register-item__facebook"
          >
            <img alt="icon-facebook" src="../assets/image/icon-facebook.svg" />
            <div class="register-item-text f-w3">Facebookで登録</div>
          </button>
          <button
            @click="nextPageEmailRegister()"
            class="register-item register-item__email"
          >
            <img alt="icon-mail" src="../assets/image/icon-mail.svg" />
            <div class="register-item-text f-w3">Eメールで登録</div>
          </button>
        </div>
      </div>
    </AppContainer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";
import ButtonBack from "@/views/Common/ButtonBack";
import Cookies from "js-cookie";
export default {
  name: "Register",
  components: {
    ButtonBack
  },
  metaInfo() {
    return {
      title: "登録",
      titleTemplate: "%s | PreGo"
    };
  },
  computed: {
    ...mapGetters({
      urlRedirectFacebook: "auth/urlRedirectFacebook",
      urlRedirectLine: "auth/urlRedirectLine"
    })
  },
  created() {
    if (this.$route.query.code) {
      Cookies.set("invitation_code", this.$route.query.code);
    } else {
      if (Cookies.get("invitation_code")) {
        Cookies.remove("invitation_code");
      }
    }
    this.$store.dispatch("auth/getUrlRedirectFacebook");
    this.$store.dispatch("auth/getUrlRedirectLine", {
      sex: localStorage.getItem("sex"),
      type: 2
    });
  },
  methods: {
    redirectUrlFacebook() {
      localStorage.removeItem("step");
      localStorage.setItem("type", 2);
      this.$root.$refs.loading.start();
      const urlRedirect = this.urlRedirectFacebook;
      this.$root.$refs.loading.finish();
      if (urlRedirect !== null) {
        window.location = urlRedirect;
      }
    },
    redirectUrlLine() {
      localStorage.removeItem("step");
      localStorage.setItem("type", 2);
      this.$root.$refs.loading.start();
      const urlRedirect = this.urlRedirectLine;
      this.$root.$refs.loading.finish();
      if (urlRedirect !== null) {
        window.location = urlRedirect;
      }
    },
    nextPageEmailRegister() {
      this.$root.$refs.loading.start();
      if (this.$route.query.code) {
        router.push({
          name: "EmailRegister",
          query: { code: this.$route.query.code }
        });
      } else {
        router.push({ name: "EmailRegister" });
      }
      this.$root.$refs.loading.finish();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/register.scss";
@import "~@/assets/scss/_fontFamily.scss";
#register {
  /deep/ .app-body._header .app-container__content {
    background-color: #f5f5f5;
  }
}
</style>
